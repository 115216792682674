.App {
  text-align: center;
  background: url(img/background1.jpg); /* Replace with your background image path */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  position: relative; /* To position floating menu */
}

.floating-menu {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  padding: 10px;
  border-radius: 5px;
  z-index: 10; /* Ensure it stays on top */
  width: 150px; /* Adjust width as needed */
}

.menu-item {
  display: block;
  padding: 8px 12px;
  margin-bottom: 5px;
  background-color: transparent;
  color: rgba(249, 235, 209, 0.9);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Alegreya', sans-serif; /* Use a font from your design */
  font-size: 14px; 
  text-align: left;
}

.menu-item:hover,
.menu-item.active {
  background-color: rgba(249, 235, 209, 0.2);
}