.quoteBox {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
   /* background: rgba(35, 35, 35, 0.55); */
    max-width: 55%;
    min-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.25);

}
/* Set box color for odd rows */
.quoteBox:nth-child(odd) {background: rgba(35, 35, 35, 0.55);}

/* Set box color for even rows */
.quoteBox:nth-child(even) {background: rgba(61, 61, 61, 0.55);}

.quoteText{
    font-family: Alegreya;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #F5F3F5;
    letter-spacing: 1px;
    clear: both;   
}

.quoteAuthor{
    font-family: Alegreya;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #F5F3F5;
    text-align: right;
}