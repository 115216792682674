@font-face {
    font-family: "Traditional Arabic";
    src: url(https://www.diuna.art/fonts/trado.ttf) format("truetype");
  }
  
  .App-header {
  
    font-family: "Traditional Arabic";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: rgba(249, 235, 209, 0.9);
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  }
  
  .App-header p {
    margin: 0;
    padding: 0;
  }
  
  .App-header img {
    margin: 8px 0; /* Add top margin of 5px */
    padding: 0;
  }

  .App-header .title {
    font-size: 120px;
    margin-bottom: -40px;
  }
  .App-header .subtitle {
    font-size: 40px;
  }
  

/* MAIN QUOTE -- BEGIN */
.mainQuoteBox {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(35, 35, 35, 0.85);
    max-width: 65%;
    min-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 40px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.35);

}

.mainQuoteText{
  font-family: Alegreya;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: rgba(249, 235, 209, 0.9);
  letter-spacing: 1px;
  clear: both;   
}

.mainQuoteAuthor{
  font-family: Alegreya;
  padding-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #F5F3F5;
  text-align: center;
}
/* MAIN QUOTE -- END */