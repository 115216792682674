.diune_footer {
    opacity: 1; /* Set the container to full opacity */ 
    color: seashell;
    font-size: 12px;
    font-style: italic;
}

.diune_footer p {
    margin: 0px;
}

.diune_footer img {
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    max-width: 350px;
    max-height: 350px;
    opacity: 0.3; /* Set the image opacity to 0.3 */ 
}

/* Style for the greetings text */
.greetings-text {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 5px; 
}